import { createReducer, on } from "@ngrx/store";
import { RegisterActions } from "../actions/registration.actions";

export const registrationFeatureKey = "registration";

export interface IClaim {
  claimType: string;
  claimValue: string;
}

export interface IRegisterClaims {
  user_type: string;
  username: string;
  fromCreatePage: string;
  hasEmailNotificationFlag: string;
  country: string;
  country_residence: string;
  allow_delete_user: string;
  view_individual_results: string;
  locale: string;
  created_by: string;
  name: string;
  email_verified: string;
  active: string;
  two_factor_enabled: string;
  two_factor_provider?: any;
  phone?: any;
  phone_verified: string;
  authentication_provider: string;
  created_by_name: string;
  created_date: string;
  modified_by_name?: any;
  modified_date?: any;
  userId: string;
  email: string;
  synlab_id: string;
  IsMigratedToProfile: string;
  given_name: string;
  family_name: string;
  ssn?: any;
  date_of_birth?: any;
  email_turned_off: string;
}

export interface IExternalAuthProvider {
  authenticationScheme: string;
  displayName: string;
}

export interface ISetPassword {
  authenticationProvider: "local" | "external";
  schemeProvider: string;
  currentPassword: string;
  newPassword: string;
}

export interface ISetPhoneNumber {
  /** phone number */
  phone: string;
  /** country ISO code */
  countryPhone: string;
  /** registration ID from query parameter */
  code: string;
}

export interface IVerifyPhoneNumber {
  /** phone number */
  phone: string;
  /** sms code */
  token: string;
  /** registration ID from query parameter */
  code: string;
}

export interface ISetMFA {
  code: string;
  twoFactorEnabled: boolean;
  twoFactorProvider: "Authenticator" | "phone";
}

export interface IAuthAppData {
  authenticatorUri: string;
  code: string;
  recoveryCodes: string[];
}

export interface ISetMFAVerify {
  code: string;
  twoFactorEnabled: boolean;
  token: string;
  tokenProvider: "Authenticator" | "phone";
}

export interface State {
  getClaimsLoading: boolean;
  claims: IRegisterClaims;
  externalAuthProviders: IExternalAuthProvider[];
  setPasswordInProgress: boolean;
  activationEmail: string;
  resendActivationLoading: boolean;
  activated: boolean;
  editPhoneLoading: boolean;
  smsCodeMFALoading: boolean;
  authAppData: IAuthAppData;
}

export const initialState: State = {
  getClaimsLoading: true,
  claims: null,
  externalAuthProviders: [],
  setPasswordInProgress: false,
  activationEmail: "",
  resendActivationLoading: false,
  activated: null,
  editPhoneLoading: false,
  smsCodeMFALoading: false,
  authAppData: null,
};

export const reducer = createReducer(
  initialState,
  on(RegisterActions.loadRegistrationClaims, (state) => ({
    ...state,
    getClaimsLoading: true,
  })),
  on(RegisterActions.loadRegistrationClaimFailed, (state) => ({
    ...state,
    getClaimsLoading: false,
  })),
  on(RegisterActions.loadRegistrationClaimSuccess, (state, { claims }) => ({
    ...state,
    getClaimsLoading: false,
    claims: claims,
  })),
  on(RegisterActions.loadExternalAuthProviderSuccess, (state, { items }) => ({
    ...state,
    externalAuthProviders: items,
  })),
  on(RegisterActions.setUserPassword, (state) => ({
    ...state,
    setPasswordInProgress: true,
  })),
  on(RegisterActions.setUserPasswordFailed, (state) => ({
    ...state,
    setPasswordInProgress: false,
  })),
  on(RegisterActions.loadTaraUserInfo, (state) => ({
    ...state,
    activationEmail: "",
  })),
  on(RegisterActions.loadTaraUserInfoSuccess, (state, { email }) => ({
    ...state,
    activationEmail: email,
  })),
  on(RegisterActions.loadTaraUserInfoFailed, (state) => ({
    ...state,
    activationEmail: "",
  })),
  on(RegisterActions.taraResendEmailActivation, (state) => ({
    ...state,
    resendActivationLoading: true,
  })),
  on(
    RegisterActions.taraResendEmailActivationFailed,
    RegisterActions.taraResendEmailActivationSuccess,
    (state) => ({
      ...state,
      resendActivationLoading: false,
    })
  ),
  on(RegisterActions.activateTaraUser, (state) => ({
    ...state,
    activated: null,
  })),
  on(RegisterActions.activateTaraUserSuccess, (state, { activated }) => ({
    ...state,
    activated: activated,
  })),
  on(RegisterActions.activateTaraUserFailed, (state) => ({
    ...state,
    activated: false,
  })),
  on(
    RegisterActions.updatePhoneNumber,
    RegisterActions.verifyPhoneNumber,
    (state) => ({
      ...state,
      editPhoneLoading: true,
    })
  ),
  on(
    RegisterActions.updatePhoneNumberSuccess,
    RegisterActions.updatePhoneNumberFailed,
    RegisterActions.verifyPhoneNumberFailed,
    RegisterActions.verifyPhoneNumberSuccess,
    (state) => ({
      ...state,
      editPhoneLoading: false,
    })
  ),
  on(RegisterActions.enableMFAVerify, (state) => ({
    ...state,
    smsCodeMFALoading: true,
  })),
  on(
    RegisterActions.enableMFAVerifyFailed,
    RegisterActions.enableMFAVerifySuccess,
    (state) => ({
      ...state,
      smsCodeMFALoading: false,
    })
  ),
  on(RegisterActions.setAuthenticatorAppData, (state, { data }) => ({
    ...state,
    authAppData: data,
  }))
);
