import { createReducer, on } from "@ngrx/store";
import { ELoginMethod } from "src/app/appsettings";
import { alphanumeric } from "src/app/modules/shared/regex-pattern";
import { Config } from "src/app/services/config/config.service";
import { CountryInfo } from "src/app/services/country/country.service";
import { isGeneplanetEnrolled } from "src/app/utils/geneplanet.util";
import { Claim, STSActions } from "../actions/sts.actions";

export const stsFeatureKey = "sts";

export interface UserInfo {
  sub: string;
  synlab_id: string;
  family_name?: string;
  given_name?: string;
  email?: string;
}

export interface State {
  synlabId: string;
  role: string[];
  name: string;
  country: string;
  email: string;
  config: Config;
  token: string;
  userInfo: UserInfo;
  loginStatus: boolean;
  claims: Claim[],
  idp: ELoginMethod;
  countryInfo: {
    status: 'loading' | 'ok';
    data: CountryInfo;
  };
  hasGeneplanetOrders: boolean | null;
  isGenePlanetEnrolled: boolean | null;
}

export const initialState: State = {
  synlabId: "",
  role: [],
  name: null,
  country: null,
  email: "",
  config: null,
  token: null,
  userInfo: null,
  loginStatus: false,
  claims: null,
  idp: null,
  countryInfo: {
    status: 'ok',
    data: null
  },
  hasGeneplanetOrders: null,
  isGenePlanetEnrolled: null
};

export const reducer = createReducer(
  initialState,
  on(STSActions.loadStssSuccess, (state, { data }) => ({
    ...state,
    ...data,
  })),
  on(STSActions.loadConfigSuccess, (state, { data }) => ({
    ...state,
    config: data,
  })),
  on(STSActions.addUserInfo, (state, { data }) => ({
    ...state,
    userInfo: data
  })),
  on(STSActions.checkLoginStatus, (state, { value }) => ({
    ...state,
    loginStatus: value
  })),
  on(STSActions.loadProfileSuccess, (state, { claims }) => ({
    ...state,
    claims: claims,
    isGenePlanetEnrolled: isGeneplanetEnrolled(claims)
  })),
  on(STSActions.loadProfileFailed, (state, { error }) => ({
    ...state,
    claims: null
  })),
  on(STSActions.loadCountrySuccess, (state, { payload }) => ({
    ...state,
    countryInfo: {
      status: <any>'ok',
      data: payload
    }
  })),
  on(STSActions.loadCountry, state => ({
    ...state,
    countryInfo: {
      ...state.countryInfo,
      status: <any>'loading'
    }
  })),
  on(STSActions.loadCountryFailed, state => ({
    ...state,
    countryInfo: {
      status: <any>'ok',
      data: {
        ...(state.countryInfo.data || {} as any),
        ssnRegex: (alphanumeric as any)
      }
    }
  })),
  on(STSActions.setClaims, (state, { claims }) => ({
    ...state,
    claims,
    isGenePlanetEnrolled: isGeneplanetEnrolled(claims)
   })),
  on(STSActions.setProfileEmail, (state, { email }) => ({
    ...state,
    email: email
  })),
  on(STSActions.setHasGeneplanetOrders, ((state, { hasOrders} )=> ({
    ...state,
    hasGeneplanetOrders: hasOrders
  }))),
  on(STSActions.setGeneplanetEnrolled, ((state, {isEnrolled} )=> ({
    ...state,
    isGenePlanetEnrolled: isEnrolled
  }))),
);
