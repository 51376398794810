import { FormConfigInterface } from "./interfaces/form-config.interface";

export const HU_CONFIG: FormConfigInterface = {
    completeProfile: [
        { "Name": "given_name", "Required": true, "Type": "text", "Order": 1 },
        { "Name": "family_name", "Required": true, "Type": "text", "Order": 2 },
        { "Name": "email", "Required": true, "Type": "text", "Order": 3 },
        { "Name": "phone", "Required": true, "Type": "phone", "Order": 4 },
        { "Name": "locale", "Required": true, "Type": "locale-picker", "Order": 5 },
        { "Name": "gender", "Required": true, "Type": "gender-picker", "Order": 6 }
    ],
    personalInfo: [
        { "Name": "given_name", "Required": true, "Type": "text", "Order": 0 },
        { "Name": "family_name", "Required": true, "Type": "text", "Order": 0 },
        { "Name": "gender", "Required": true, "Type": "gender-picker", "Order": 0 }
    ],
    contact: [
        { "Name": "country_address", "Required": false, "Type": "country-picker", "Order": 1 },
        { "Name": "phone", "Required": true, "Type": "phone", "Order": 2 },
        { "Name": "email", "Required": true, "Type": "text", "Order": 3 }
    ]
};

