import { FormConfigInterface } from "./interfaces/form-config.interface";

export const ES_TC_CONFIG: FormConfigInterface = {
    completeProfile: [
        { "Name": "given_name", "Required": true, "Type": "text", "Order": 1 },
        { "Name": "family_name", "Required": true, "Type": "text", "Order": 2 },
        { "Name": "email", "Required": true, "Type": "text", "Order": 3 },
        { "Name": "phone", "Required": true, "Type": "phone", "Order": 8 },
        { "Name": "locale", "Required": true, "Type": "locale-picker", "Order": 4 },
        { "Name": "country_residence", "Required": true, "Type": "country-picker", "Order": 5 },
        { "Name": "date_of_birth", "Required": true, "Type": "date-picker", "Order": 6 },
        { "Name": "gender", "Required": true, "Type": "gender-picker", "Order": 7 }
    ],
    completeProfileExpert: [
        { "Name": "medical_seal_number", "Required": false, "Type": "text", "Order": 5.1 },
        {
            "Name": "title", "Required": true, "Type": "select-single", "Order": 0.1,
            "Options": {
                "FetchFromUrl": false,
                "Url": null,
                "Items": [
                    { "Value": "Dr.", "Label": "Dr." },
                    { "Value": "Prof.", "Label": "Prof." },
                    { "Value": "Prof. Dr.", "Label": "Prof. Dr." }
                ]
            }
        }
    ],
    completeProfileExpertAdditionalInfo: [
        {
            "Name": "institutions", "Required": true, "Type": "institutions-selector", "Order": 9,
            "Options": {
                "AddNewItemEnabled": false,
                "FetchFromUrl": true,
                "Url": "/v2/manage/accounts/expert-dropdown-items-with-paging?&category=Institutions&page=1&pageSize=1000",
                "Items": []
            }
        }
    ],
    personalInfo: [
        { "Name": "given_name", "Required": true, "Type": "text", "Order": 0 },
        { "Name": "family_name", "Required": true, "Type": "text", "Order": 0 },
        { "Name": "country_residence", "Required": true, "Type": "country-picker", "Order": 0 },
        { "Name": "date_of_birth", "Required": true, "Type": "date-picker", "Order": 0 },
        { "Name": "gender", "Required": true, "Type": "gender-picker", "Order": 0 }
    ],
    contact: [
        { "Name": "country_address", "Required": false, "Type": "country-picker", "Order": 0 },
        { "Name": "city", "Required": false, "Type": "text", "Order": 0 },
        { "Name": "zipcode", "Required": false, "Type": "text", "Order": 0 },
        { "Name": "address", "Required": false, "Type": "text", "Order": 0 },
        { "Name": "phone", "Required": true, "Type": "phone", "Order": 0 },
        { "Name": "email", "Required": true, "Type": "text", "Order": 0 }
    ],
    expertPersonalInfo: [
        { "Name": "title", "Required": true, "Type": "salutation-selector" },
        { "Name": "given_name", "Required": true, "Type": "text" },
        { "Name": "family_name", "Required": true, "Type": "text" },
        { "Name": "country_residence", "Required": true, "Type": "country-picker" },
        { "Name": "medical_seal_number", "Required": false, "Type": "text" }
    ],
    expertContact: [
        { "Name": "phone", "Required": true, "Type": "phone" },
        { "Name": "email", "Required": true, "Type": "text" }
    ]
};
