import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { BehaviorSubject, EMPTY, Observable, of, pipe, throwError } from "rxjs";
import {
  Account,
  activeClaimType,
  Claim,
  getClaimValueWithType,
  updateClaims,
} from "./accounts.model";
import {
  tap,
  map,
  withLatestFrom,
  filter,
  flatMap,
  take,
} from "rxjs/operators";
import { GetByEmailResponse, UserAccountInfo } from "./user.model";
import { HttpErrorResponse } from "@angular/common/http";
import { Consents } from "src/app/@store/reducers/profile.reducer";

export interface CreateUserResult {
  active: string;
  isNewUser: boolean;
  synlabId: string;
  userId: string;
}

/** claim value to check if user is using external authentication. */
export const externalProviderValue = "external";
/** Email domain name for SYNLAB accounts.
 * Accounts with such email domain can use SYNLAB active directory authentication
 */
export const synlabDomain = "synlab.com";

/** Constant value as key to find claim value for `locale`. */
export const countryClaimType = "country";
/** Constant value as key to find claim value for `locale`. */
export const localeClaimType = "locale";
/** Constant value as key to find claim value for `authentication_provider`. */
export const authProviderClaimType = "authentication_provider";
/** Constant value as key to find claim value for `email`. */
export const emailClaimType = "email";
export const allowedDuplicateClaimTypes = ["user_type"];

const removeDuplicateClaims = pipe(
  map((claims: Claim[]) => {
    const claimsMap = new Map();
    claims
      .filter((c) => allowedDuplicateClaimTypes.indexOf(c.claimType) === -1)
      .forEach((c) => claimsMap.set(c.claimType, c.claimValue));
    let distinctClaims: Claim[] = [];
    claimsMap.forEach((v, k) =>
      distinctClaims.push({ claimType: k, claimValue: v })
    );
    const duplicableClaims = claims.filter(
      (c) => allowedDuplicateClaimTypes.indexOf(c.claimType) > -1
    );
    distinctClaims.push(...duplicableClaims);
    return distinctClaims;
  })
);
/** Service for api requests to manage account. */
@Injectable({
  providedIn: "root",
})
export class AccountsService {
  private readonly updateConsentEndpoint = "/api/consent/user";

  /** Store for latest fetched account information */
  private latestAccountInfo = new BehaviorSubject<Account>(undefined);
  /** Public Observable to get latest user account info. */
  public latestAccountInfo$ = this.latestAccountInfo.asObservable();
  public latestAccountInfoObj$ = new BehaviorSubject<any>(undefined);
  /** Observable to get latest user account's locale code. */
  public latestAccountLocaleCode$ = this.latestAccountInfo$.pipe(
    map((claims) => getClaimValueWithType(localeClaimType, claims))
  );
  /** Observable to get latest user account's authentication provider. */
  public latestAccountAuthProvider$ = this.latestAccountInfo$.pipe(
    map((claims) => getClaimValueWithType(authProviderClaimType, claims))
  );
  public country$ = this.latestAccountInfo$.pipe(
    map((claims) => getClaimValueWithType(countryClaimType, claims))
  );
  /** Observable that indicates if current account is from SYNLAB domain. */
  public isSynlabAccount$ = this.latestAccountInfo$.pipe(
    filter((x) => !!x),
    map((claims) => getClaimValueWithType(emailClaimType, claims)),
    map(this.isSynlabAccount)
  );
  /** Observable to get if user is deactivated.
   * if there is no data, should not mark as deactivated in case of failed fetch */
  public deactivated$ = this.latestAccountInfo$.pipe(
    map((claims) => getClaimValueWithType(activeClaimType, claims)),
    map(
      (active) =>
        typeof active === "string" && active.trim().toLowerCase() === "false"
    )
  );
  constructor(private api: ApiService) {}

  /** Get user account information
   * @param id User's id
   * * stores latest fetched user info into `latestAccountInfo`
   */
  public get(id: string): Observable<Account> {
    return this.api.get(`/manage/accounts/${id}`).pipe(
      removeDuplicateClaims,
      tap((claims: any[]) => {
        this.latestAccountInfo.next(claims);
        this.latestAccountInfoObj$.next(
          claims.reduce((acc, val) => {
            acc[val.claimType] = val.claimValue;
            return acc;
          }, {})
        );
      })
    );
  }

  public getConsent(synlabId?: string, categoryName?: string): Observable<Consents[]> {
    if(synlabId){
      return this.api.get(`/api/consent/category/${categoryName}/user/${synlabId}`);
    }
    return this.api.get(`/api/consent/category/${categoryName}`);
  }

  public updateConsent(cons, synlabId){
    if(!cons.length){
      return of(true);
    }
    return this.api.put(
    `${this.updateConsentEndpoint}/${synlabId}`, cons);
  }

  /** Get user by providing synlabId
   * @param synlabId User's SynlabId
   */
  public getBySynlabId(synlabId: string): Observable<Account> {
    return this.api
      .get<{ users: UserAccountInfo[] }>("/manage/accounts", { synlabId })
      .pipe(
        flatMap((res) => {
          if (!res || res.users.length !== 1) {
            return throwError(`Could not find user with synlabId: ${synlabId}`);
          } else {
            return this.get(res.users[0].id);
          }
        })
      );
  }

  /** Create user by providing claims of the user
   * @param body claims of the user
   */
  public post(body: Account) {
    return this.api.post<CreateUserResult>(`/manage/accounts`, body);
  }

  /** Update user account information
   * @param body User updated info
   * * stores latest sent user info into `latestAccountInfo`
   */
  public put(body: Account): Observable<Account> {
    return this.api.put(`/manage/accounts`, body).pipe(
      withLatestFrom(this.latestAccountInfo$),
      tap(([, latest = []]: [any, Account]) =>
        this.latestAccountInfo.next(updateClaims(latest, body))
      )
    );
  }

  /** Update account that is not current user's own
   * @param body User updated info
   */
  public wardPut(body: Account, userId: string): Observable<Account> {
    return this.api.put(`/manage/accounts/${userId}`, body);
  }

  /**Will send an email to confirm the deletion */
  public sendDeleteEmail(userId){
    return this.api.post(`/v2/manage/accounts/request-delete-user/${userId}`, undefined, {responseType: 'text'});
  }

  /**Confirmation of delete */
  public confirmDelete(body){
    return this.api.put(`/v2/manage/accounts/confirm-delete-user`, body, {responseType: 'text'});
  }

  public getDeleteValidity(params): Observable<boolean>{
    return this.api.get(`/v2/manage/accounts/verify-delete-user`, params);
  }

  public fetchPendingBooking(synlabId){
    return this.api.get(`/api/booking/checkhaspending/${synlabId}`);
  }

  /**
   * Check if email is a SYNLAB account. Checks if domain is same as `synlabDomain`
   * @param email string representing email
   */
  isSynlabAccount(email: string = "") {
    return email.trim().toLocaleLowerCase().endsWith(`@${synlabDomain}`);
  }

  /** fetch user claims if not fetched yet */
  public fetchInitialUserClaims(userId: string) {
    this.latestAccountInfo$
      .pipe(
        take(1),
        flatMap((info) => {
          if (info === undefined) {
            return this.get(userId);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  public getByEmail(email: string) {
    return this.api.get<GetByEmailResponse>(`/manage/accounts/search/${email}`);
  }
  /** resend activation link to user with `userId` provided */
  public resendActivationEmail(userId: string) {
    return this.api.put(`/manage/accounts/resend_invitation/${userId}`);
  }
}
