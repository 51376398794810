import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { claimTypes } from 'src/app/appsettings';
import { ConsentsPayload } from 'src/app/pages/index/pages/notification-settings/notification.model';
import { Claim } from 'src/app/services/accounts/accounts.model';
import { ConfigService } from 'src/app/services/config/config.service';
import { ApiService } from '../api/api.service';

const allowedDuplicateClaimTypes: string[] = [claimTypes.USER_TYPE];
@Injectable({
  providedIn: 'root'
})
export class RegisterProfileApiService {
  private readonly registerProfileEndpoint = "/manage/accounts/complete_profile";
  private readonly rollbackInactivatedUserEndpoint = "/v2/manage/accounts/rollback-inactivated-user";
  private readonly getPtProfileEndpoint = "/manage/accounts";
  private readonly updatePtProfileEndpoint = "/manage/accounts/complete_profile_ptee";

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private api: ApiService,
  ) { }
  getRegisterProfile(regId: string, seqId: string): Observable<Claim[]> {
    return this.http.get<Claim[]>( `${this.config.baseAuthUrl}${this.registerProfileEndpoint}`,
    { params: { id: regId, seqId: seqId } } ).pipe(this.removeDuplicateClaims)
  }

  getPtExisitngProfile(userId: string): Observable<Claim[]> {
    return this.http.get<Claim[]>(
      `${this.config.baseAuthUrl}${this.getPtProfileEndpoint}/${userId}`,
    ).pipe(this.removeDuplicateClaims)
  }

  submitRegisterProfile(regId: string, claims: Claim[]) {
    return this.http.put( `${this.config.baseAuthUrl}${this.registerProfileEndpoint}`,
    claims, { params: { id: regId }, headers: this.api.headers() } )
  }

  updateConsents(consents: ConsentsPayload[], synlabId: string, seqId: string){
    if (!consents) { return of(true); }
    if(seqId){
      return this.http.put( `${this.config.baseAccountUrl}/api/consent/user/${synlabId}/register/${seqId}`, consents );
    }
    return this.http.put( `${this.config.baseAccountUrl}/api/consent/user/${synlabId}`, consents );
  }

  updatePtExistingProfile(userId: string, claims: Claim[]): Observable<unknown> {
    return this.http.put( `${this.config.baseAuthUrl}${this.updatePtProfileEndpoint}`,
    claims, { params: { id: userId } } )
  }

  /**
   * will delete user when signup to TARA button is clicked
  */
  rollBackUser(userId: string) {
    return this.http.put( `${this.config.baseAuthUrl}${this.rollbackInactivatedUserEndpoint}`,
    null, { params: { userId: userId }, responseType: 'text' } )
  }

  /**
   * sometimes, the api returns duplicate claims for claims that are supposed
   * to be unique. since this is an adjustment for the api, this fn is placed
   * here.
   */
  private removeDuplicateClaims = pipe(
    map((claims: Claim[]) => {
      const claimsMap = new Map();
      claims
        .filter((c) => allowedDuplicateClaimTypes.indexOf(c.claimType) === -1)
        .forEach((c) => claimsMap.set(c.claimType, c.claimValue));
      let distinctClaims: Claim[] = [];
      claimsMap.forEach((v, k) =>
        distinctClaims.push({ claimType: k, claimValue: v })
      );
      const duplicableClaims = claims.filter(
        (c) => allowedDuplicateClaimTypes.indexOf(c.claimType) > -1
      );
      distinctClaims.push(...duplicableClaims);
      return distinctClaims;
    })
  );
}
