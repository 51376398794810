import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import * as _ from "lodash";
import { of } from "rxjs";
import { AuthService } from "../auth/auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService
  ) { }
  canActivate() {
    const isLoggedIn = this.authService.isLoggedInObs();
    const sessionTransferObj = JSON.parse(localStorage.getItem("session-transfer"));

    if(sessionTransferObj != null && sessionTransferObj.accessToken) {
      this.authService.saveToState(sessionTransferObj.accessToken);
      return of(true);
    } else {
      isLoggedIn.subscribe(loggedin => {
        if (!loggedin) {
          this.authService.signinRedirectTrigger$.next(Date.now());
        }
      });

      return isLoggedIn;
    }
  }
}
