import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as RegisterProfileActions from 'src/app/@store/actions/register-profile.actions';
import { RegisterProfileApiService } from 'src/app/services/register-profile-api/register-profile-api.service';
import { getErrorMessage } from 'src/app/utils/error.util';
@Injectable()
export class RegisterProfileEffects {
  constructor(
    private actions$: Actions,
    private registerProfileApi: RegisterProfileApiService
  ) { }
  loadRegisterProfile$ = createEffect(() => this.actions$.pipe(
    ofType(RegisterProfileActions.loadRegisterProfile),
    mergeMap(action => this.registerProfileApi.getRegisterProfile(action.regId, action.seqId)),
    map(claims => RegisterProfileActions.loadRegisterProfileSuccess({ data: claims })),
    catchError((error: HttpErrorResponse, caught) => {
      const msg = getErrorMessage(error);
      return merge(
        of(RegisterProfileActions.loadRegisterProfileFailure({
          error: msg,
          errorObject: error
        })),
        caught
      );
    })
  ))

  loadExistingPTProfile$ = createEffect(() => this.actions$.pipe(
    ofType(RegisterProfileActions.loadExistingPTProfile),
    mergeMap(action => this.registerProfileApi.getPtExisitngProfile(action.userId)),
    map(claims => RegisterProfileActions.loadExistingPTProfileSuccess({ data: claims })),
    catchError((error: HttpErrorResponse, caught) => {
      const msg = getErrorMessage(error);
      return merge(
        of(RegisterProfileActions.loadExistingPTProfileFailure({
          error: msg,
          errorObject: error
        })),
        caught
      );
    })
  ))

  submitRegisterProfile$ = createEffect(() => this.actions$.pipe(
    ofType(RegisterProfileActions.submitRegisterProfile),
    mergeMap(action =>
      this.registerProfileApi.submitRegisterProfile(
        action.regId,
        action.data
        )
        .pipe(map((res) => [res, action] as const))
      )
    ,
    switchMap(
      ([res, action]) =>
         this.registerProfileApi.updateConsents(action.consents, action.synlabId, action.seqId)
    ),
    map(() => RegisterProfileActions.submitRegisterProfileSuccess()),
    catchError((error: HttpErrorResponse, caught) => {
      const msg = getErrorMessage(error);
      return merge(
        of(RegisterProfileActions.submitRegisterProfileFailure({ error: msg, errorObject: error })),
        caught
      )
    })
  ))

  updatePtExisitingProfile$ = createEffect(() => this.actions$.pipe(
    ofType(RegisterProfileActions.updatePtExistingProfile),
    mergeMap(action =>
      this.registerProfileApi.updatePtExistingProfile(
        action.userId,
        action.data)
        .pipe(map((res) => [res, action] as const))),
    switchMap(
      ([res, action]) =>
         this.registerProfileApi.updateConsents(action.consents, action.synlabId, action.seqId)
    ),
    map(() => RegisterProfileActions.updatePtExistingProfileSuccess()),
    catchError((error: HttpErrorResponse, caught) => {
      const msg = getErrorMessage(error);
      return merge(
        of(RegisterProfileActions.updatePtExistingProfileFailure({ error: msg, errorObject: error })),
        caught
      )
    })
  ))

  signupToTaraCLicked$ = createEffect(() => this.actions$.pipe(
    ofType(RegisterProfileActions.signupToTaraClicked),
    mergeMap(action => this.registerProfileApi.rollBackUser(action.userId)),
    map(() => RegisterProfileActions.signupToTaraClickedSuccess()),
    catchError((error: HttpErrorResponse, caught) => {
      const msg = getErrorMessage(error);
      return merge(
        of(RegisterProfileActions.signupToTaraClickedFailure({
          error: msg,
          errorObject: error
        })),
        caught
      );
    })
  ))
}
