import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, switchMap, share, first, map, finalize } from "rxjs/operators";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { claimValues } from "src/app/appsettings";
import { ApiService } from "src/app/services/api/api.service";
import { ConfigService } from "src/app/services/config/config.service";

const CONFIRM_EMAIL_API = "/v2/uk/manage/accounts/confirm-email";

@Component({
  selector: "syn-activate-account",
  templateUrl: "./activate-account.component.html",
  styleUrls: ["./activate-account.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActivateAccountComponent {

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  success$: Observable<boolean> = this.activeRt.queryParamMap.pipe(
    first(),
    share(),
    switchMap((params) => this.putConfirmEmail(
      params.get('seqId'),
      params.get('id')
    )),
    finalize(() => this.loading$.next(false))
  );

  error$: BehaviorSubject<string|null> = new BehaviorSubject(null);

  loginLink$: Observable<string> = this.config.envConfig$.pipe(map(c => c.BaseAuthUrl));
  isSHFYInstance$ = this.store.pipe(select(StsSelectors.isUkShfyInstance));
  isActiveUser: boolean = false;
  isLinkExpired: boolean = false;
  isDuplicateError: boolean = false;

  constructor(
    private store: Store,
    private activeRt: ActivatedRoute,
    private api: ApiService,
    private config: ConfigService
  ) { }

  private putConfirmEmail(seqId:string, id:string): Observable<boolean> {
    return this.api.put(CONFIRM_EMAIL_API, { seqId, id }).pipe(
      map(res => `${res}`.toLowerCase() == claimValues.TRUE),
      catchError((err: HttpErrorResponse) => {
        this.isActiveUser = err.error.errorCode === 'SYNERR-0020';
        this.isLinkExpired = err.error.errorCode === 'SYNERR-0003';
        this.isDuplicateError = err.error.errorCode === 'SYNERR-0047';
        this.error$.next(err.error?.message);
        return of(false);
      })
    );
  }
}
