import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ReplaySubject } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { shareReplay } from 'rxjs/operators';
import { Locale } from "../agreement/agreement.service";
import { FormFieldConfig } from "src/app/models/form-field-config.model";
import { FORM_CONFIG } from "src/app/form-configs";
import { FormConfigInterface } from "src/app/form-configs/interfaces/form-config.interface";

/** list of country codes which needs to show MFA setup on account activation */
export const initialMultiFactorAuthCountries = ["fr"];

export interface IEnvJson {
  OIDC: { ClientId: string; ResponseType: string; Scope: string; };
  ApplicationInsights: { InstrumentationKey: string };
  ApplicationMonitoring: { Enabled: boolean; ApplicationName: string };
}

export interface Config {
  Environment: string;
  Version: string;
  ApplicationName: string;
  BaseAuthUrl: string;
  BaseAdminUrl: string;
  BaseProfileApiUrl: string;
  BaseProfileUrl: string;
  BasePlasmaUrl: string;
  BaseUefaUrl: string;
  BaseWebreqUrl: string;
  BaseWebreqAdminUrl: string;
  BaseBookingUrl: string;
  EeBaseBookingUrl?: string;
  BookingLinkEnabled: string;
  TwilioEnabled: string;
  WhiteLabelEnabled: string;
  WhiteLabelName: string;
  WardLimit: string;
  UseProfileAPI: string;
  EnablePreventionWellness: string;
  PetProfileEnabled: string;
  WardProfileEnabled: string;
  baseResultsApiUrl: string;
  AvailableLanguage: string;
  BaseBigCommerceUrl: string;
  Country: string;
  CookieBotAPIKey: string;
  CookieBotEnabled: string;
  RequireMFA: boolean;
  DefaultLanguage: string;
  eeIdleTimeOutInSeconds: number;
  EnableOpenReplay: boolean;
  OpenReplayProjectKey: string;
  OpenReplayIngestPoint: string;
  EnableGoogleTag: string;
  GoogleTagId: string;
  ShfyDxpBaseURL: string;
  ShfyRegistrationURL: string;
  ShfyCompleteProfileURL: string;
  UseNewUI: string;
  EnableCheckResults: string;
}

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private _envConfigError: string = "Env config not loaded!";
  private _envConfig$ = new ReplaySubject<Config>(1);
  public _envConfig: Config;
  public envConfig$ = this._envConfig$.pipe(
    shareReplay({ refCount: false, bufferSize: 1 })
  );
  public envJson: IEnvJson = null;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  public getLocales() {
    return this.http.get<Locale[]>(`${this._envConfig.BaseAuthUrl}/manage/locale`);
  }

  load() {
    return this.http
      .get<Config>("/api/environment")
      .toPromise()
      .then(async data => {

        const locales = await this.http.get<Locale[]>(`${data.BaseAuthUrl}/manage/locale`).toPromise();
        const AvailableLanguage = locales.map(locale => locale.cultureCode).join(',');
        const newData: any = { ...data, AvailableLanguage };

        this._envConfig = newData;
        this._envConfig$.next(newData);
        this.store.dispatch(STSActions.loadConfigSuccess({ data: newData }));
      },
      (error) => {
        console.log(error);
        throw error;
      });
  }

  loadEnvJson() {
    return this.http.get("/assets/env.json").toPromise().then(
      (env:IEnvJson) => { this.envJson = env },
      error => { throw error; });
  }

  get baseAuthUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.BaseAuthUrl;
  }

  get baseClientUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.BaseAdminUrl;
  }

  get baseAccountUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.BaseProfileUrl;
  }

  get basePlasmaUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.BasePlasmaUrl;
  }

  get baseProfileApiUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.BaseProfileApiUrl;
  }

  get environment() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.Environment;
  }

  get version() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.Version;
  }

  get twilioEnabled() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.TwilioEnabled;
  }

  get UseProfileAPI() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.UseProfileAPI;
  }

  get shfyDxpBaseURL() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.ShfyDxpBaseURL;
  }

  get shfyRegistrationURL() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.ShfyRegistrationURL;
  }

  get shfyCompleteProfileURL() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.ShfyCompleteProfileURL;
  }

  get isWardProfileEnabled() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return (this._envConfig.WardProfileEnabled || '').toLowerCase() === 'true';
  }

  get formConfig(): FormConfigInterface {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return FORM_CONFIG[this._envConfig.WhiteLabelName];
  }
}
