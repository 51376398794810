import * as _ from "lodash";
import { Injectable, EventEmitter } from "@angular/core";
import { UserManager, UserManagerSettings, User } from "oidc-client";
import { ConfigService } from "../config/config.service";
import { BehaviorSubject, from, Observable, of, ReplaySubject } from "rxjs";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { JwtHelperService } from "@auth0/angular-jwt";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { catchError, delay, filter, map, shareReplay, switchMap, take, tap, throttleTime, } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { StsSelectors, checkTenant } from "src/app/@store/selectors/sts.selectors";
import { isInAvailableLocale } from "src/app/transloco-root.module";
import { EStorageKeys, LocalStorageKeys } from "src/app/models/storage.model";
import { getLangCodeFromLocalStorage, getLangCodeFromQueryString, isPrivateRoute, showCookieBot, } from "src/app/utils/common.utils";
import { LoggerService } from "../logger.service";
import { AccountsService } from "../accounts/accounts.service";
import { claimTypes, whiteLabelNames } from "src/app/appsettings";
import { formatLocale } from "src/app/plasma-ui-common/utils/format-locale";

export interface UserInfo {
  name: string;
  username?: string;
  country: string;
  client_id: string;
  email: string;
  email_verified: string;
  family_name: string;
  given_name: string;
  phone: string;
  preferred_username: string;
  sub: string;
  user_type: string | string[];
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private jwt = new JwtHelperService();
  /** lookup for admin types. If user_type value exists here, the user is admin. */
  public readonly admintypes = [ "superadmin", "apiadmin", "clientadmin", "identityresourceadmin", "bcp", ];
  public readonly uefa_users = [ "uefa_admin", "uefa_laboratory", "uefa_team", "uefa_official", "uefa_user", "uefa_mlo", "uefa_management", "uefa_teammlo_lab", "uefa_mlo_lab", ];
  public readonly bcp_user = ["bcp"];
  public readonly webreq_users = ["bcp", "doctor", "webreq_user"];
  public readonly synlab_access_users = ["synlab_access_user"];
  public readonly webreqadmin_users = ["webreq_admin", "webreq_superadmin"];
  public readonly admin_users = [ "superadmin", "clientadmin", "apiadmin", "identityresourceadmin", ];
  public readonly patient_users = ["patient"];
  public readonly customer_care = ["customer_care"];
  public readonly de_admin = ["de_admin"];
  public readonly es_admin = ["es_admin"];
  public readonly it_admin = ["it_admin"];
  public readonly co_admin = ["co_admin"];
  public readonly dk_admin = ["dk_admin"];
  public readonly pt_admin = ["pt_admin"];
  public readonly ee_admin = ["ee_admin"];
  public readonly air_admin = ["air_admin"];
  public readonly uk_dfp_admin = ["uk_dfp_admin"];
  public readonly uk_shfy_admin = ["uk_shfy_admin"];
  public readonly useradmin = ["useradmin"];

  public readonly school_admin = "school_admin";
  public readonly mass_company = "mass_company";
  public readonly school_teacher = "school_teacher";
  public readonly lolli_superadmin = "lolli_superadmin";
  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();
  _manager: UserManager = null;
  _user: User = null;
  _clientSettings: UserManagerSettings;

  loggedIn = false;
  profileInfo: any = null;
  profileClaims: any = [];
  userInfo = new ReplaySubject<UserInfo>(1);
  public userInfo$: Observable<UserInfo> = this.userInfo.asObservable();

  signinRedirectTrigger$ = new BehaviorSubject<any>(null);
  signinRedirectCallbackTrigger$ = new BehaviorSubject<any>(null);

  claimsIsLoaded: boolean = false;
  public redirectUrl: string = "";

  constructor(
    private config: ConfigService,
    private router: Router,
    private store: Store<AppState>,
    private _transloco: TranslocoService,
    private logr: LoggerService,
    private acc: AccountsService,
  ) {
    this._manager = new UserManager(this.getClientSettings());
    this._manager
      .getUser()
      .then((user) => {
        if (user) {
          this.logr.log("getUser() resolve event!");
          this.loggedIn = true;
          this._user = user;
          this.userLoadededEvent.emit(user);

          this.checkSavedUserInfo(user.access_token);
        } else {
          this.loggedIn = false;
        }
      })
      .catch((err) => {
        this.loggedIn = false;
      });

    this._manager.events.addUserLoaded((user) => {
      this.logr.log("addUserLoaded event called!");
      this._user = user;
      this.loggedIn = user !== undefined;
      this.checkSavedUserInfo(user.access_token);
    });

    this._manager.events.addUserUnloaded(() => {
      this.logr.log("addUserUnloaded event called!");
      this.loggedIn = false;
    });

    this._manager.events.addUserSessionChanged(() => {
      this.logr.log("addUserSessionChanged event called!");

      // will check for updated token,
      // if token expired, will be redirected to sts-login
      this.signinRedirectTrigger$.next(Date.now());
    });

    this._manager.events.addUserSignedOut(() => {
      this.logr.log("addUserSignedOut event called!");

      // will check for updated token,
      // if token expired and in a private path, will be redirected to sts-login
      if (isPrivateRoute(this.router.url, this.router.config)) {
        this.signinRedirectTrigger$.next(Date.now().toString());
      }
    });

    this._addSigninEvents();
  }

  isLoggedInObs(): Observable<boolean> {
    return from(this._manager.getUser()).pipe(map(Boolean));
  }

  checkLogin(): Observable<boolean> {
    return from(this._manager.getUser()).pipe(
      map<User, boolean>((user) => true)
    );
  }

  getClientSettings(): UserManagerSettings {
    const { OIDC } = this.config.envJson;
    const config = this.config._envConfig;

    return {
      authority: `${config.BaseAuthUrl}`,
      client_id: OIDC?.ClientId ? OIDC.ClientId : "plasmaprofile-code-pkce",
      redirect_uri: `${config.BaseProfileUrl}/signin-callback`,
      post_logout_redirect_uri: `${config.BaseProfileUrl}`,
      response_type: OIDC?.ResponseType ? OIDC.ResponseType : "code",
      scope: OIDC?.Scope ? OIDC.Scope : "openid profile email address user_type IdentityServerApi offline_access synlab_id",
      filterProtocolClaims: true,
      loadUserInfo: false,
      silent_redirect_uri: `${config.BaseProfileUrl}/renewtoken`,
      automaticSilentRenew: true,
      revokeAccessTokenOnSignout: true,
      monitorSession: true,
      extraQueryParams: {
        ...this.getExtraQueryParams
      },
    };
  }

  private get getExtraQueryParams() {
    const config = this.config._envConfig;
    if (config) {
      const isEsTc: boolean = checkTenant(whiteLabelNames.SPAIN_TELECOUNSELING, config);
      if (isEsTc) {
        const medioCallLinkEncoded: string = new URLSearchParams(window.location.search)?.get("uid");
        return medioCallLinkEncoded ? { return_to: medioCallLinkEncoded } : {}
      }
    }
    return {};
  }

  startSignoutMainWindow() {
    this._manager.getUser().then((user) => {
      return this._manager
        .signoutRedirect({ id_token_hint: user?.id_token })
        .then((resp) => {
          this.logr.log("startSignoutMainWindow -> signoutRedirect done!", resp);
          this.clearAppLanguageFromStorage();
        })
        .catch( (err) => this.logr.log(err));
    });
  }

  checkUserAccess(types: string[] = []) {
    const allowedUsers = types.map((c) => { return c.toLowerCase(); });
    let userType = [];

    const c = this.profileInfo || null;
    if (c) {
      const { user_type } = c;
      if (user_type && typeof user_type === "string") {
        userType = [user_type.toLowerCase()];
      } else {
        userType = user_type.map((c) => { return c.toLowerCase(); });
      }
    }

    return _.intersection(userType, allowedUsers).length ? true : false;
  }
  isAdminUser(): boolean {
    return this.checkUserAccess(this.admin_users);
  }
  isAppUser(): boolean {
    return this.checkUserAccess(this.synlab_access_users);
  }
  isWebreqAdminUser(): boolean {
    return this.checkUserAccess(this.webreqadmin_users);
  }
  isWebreqUser(): boolean {
    return this.checkUserAccess(this.webreq_users);
  }
  isUefaUser(): boolean {
    return this.checkUserAccess(this.uefa_users);
  }
  isPatient(): boolean {
    return this.checkUserAccess(this.patient_users);
  }
  isCustomerCare(): boolean {
    return this.checkUserAccess(this.customer_care);
  }
  isColombiaAdmin(): boolean {
    return this.checkUserAccess(this.co_admin);
  }
  isDenmarkAdmin(): boolean {
    return this.checkUserAccess(this.dk_admin);
  }
  isPortugalAdmin(): boolean {
    return this.checkUserAccess(this.pt_admin);
  }
  isAirAdmin(): boolean {
    return this.checkUserAccess(this.air_admin);
  }
  isBCP(): boolean {
    return this.checkUserAccess(this.bcp_user);
  }

  isCountryAdmin(): boolean {
    return this.checkUserAccess(
      this.co_admin.concat(
        this.pt_admin,
        this.air_admin,
        this.dk_admin,
        this.de_admin,
        this.es_admin,
        this.it_admin,
        this.ee_admin,
        this.uk_dfp_admin,
        this.uk_shfy_admin
      )
    );
  }
  isUserAdmin(): boolean {
    return this.checkUserAccess(this.useradmin);
  }
  isSchoolAdmin(): boolean {
    return this.checkUserAccess([this.school_admin]);
  }
  isSchoolTeacher(): boolean {
    return this.checkUserAccess([this.school_teacher]);
  }
  isLolliSuperAdmin(): boolean {
    return this.checkUserAccess([this.lolli_superadmin]);
  }
  isMassCompany(): boolean {
    return this.checkUserAccess([this.mass_company]);
  }

  saveToState(token: string) {
    showCookieBot(true, 500);

    const decoded = this.jwt.decodeToken(token);
    const { idp, langCode, sub } = decoded;
    this.store.dispatch( STSActions.loadStssSuccess({ data: { token: token, idp} as any }) );
    this.getClaims(sub);

    // Set langCode from JWT
    if (langCode) {
      this.setAppLanguageFromJwtToken(langCode);
    }
  }

  checkSavedUserInfo(user) {
    this.store
      .pipe(select(StsSelectors.getSynlabId))
      .pipe(
        filter((synlabId) => !synlabId),
        take(1),
        tap((n) => {
          this.saveToState(user);
        })
      )
      .subscribe();
  }

  setAppLanguageFromJwtToken(lang: string) {
    const langCodeFromStorage = getLangCodeFromLocalStorage();
    const langCodeFromQueryString: string = getLangCodeFromQueryString();

    this.config.envConfig$
      .pipe(
        filter((x) => Boolean(x)),
        take(1),
        tap((config) => {
          const getLang = isInAvailableLocale;
          const configLanguages = config.AvailableLanguage?.split(",");
          let availableLanguages = [
            this._transloco.getDefaultLang(),
            ...(Array.isArray(configLanguages) ? configLanguages : []),
          ];

          const isJwtLanguageAvailable =
            lang && getLang(lang, availableLanguages) != "";
          this.logr.log({ isJwtLanguageAvailable, lang })

          if (
            !langCodeFromQueryString &&
            !langCodeFromStorage &&
            isJwtLanguageAvailable
          ) {

            let langToSet = formatLocale(getLang(lang, availableLanguages));
            this.logr.log('Setting from JWT', langToSet);
            sessionStorage.setItem(LocalStorageKeys.language, langToSet);
            this._transloco.setActiveLang(langToSet);
          }
        })
      )
      .subscribe();
  }

  clearAppLanguageFromStorage() {
    sessionStorage.removeItem(LocalStorageKeys.language);
  }

  private _addSigninEvents() {
    this.signinRedirectTrigger$
    .pipe(
      filter((v) => !!v),
      throttleTime(1000),
      tap((evt) => {
        from(this._manager.signinRedirect({ data: null })).pipe(
          take(1),
          tap(() => {
            this.logr.log("startSigninMainWindow -> signinRedirect done!");
          })
        ).subscribe();
      }),
      catchError(error => {
        this.logr.log(error);
        throw new Error("signinRedirect() has encountered and error!");
      })
    ).subscribe();

    this.signinRedirectCallbackTrigger$
      .pipe(
        filter((v) => !!v),
        throttleTime(1000),
        tap((evt) => {
          from(this._manager.signinRedirectCallback()).pipe(
            take(1),
            tap(user => {
              this.logr.log("endSigninMainWindow -> signinRedirectCallback done!\n", user);
              document.querySelector('body').classList.remove('signin-redirecting');

              // check if "EStorageKeys.REDIRECT_URL" exists in storage
              const redirectURL = localStorage.getItem(EStorageKeys.REDIRECT_URL);
              const initialRoute = (redirectURL?.includes('signin-callback') ? "/" : redirectURL) || "/";

              this.logr.log("Redirect to initial route:\n", initialRoute);
              this.router.navigateByUrl(initialRoute).then(done => {
                this.logr.log('navigation to initialRoute success', { initialRoute })
              })

              // clear redirect url in storage
              localStorage.removeItem(EStorageKeys.REDIRECT_URL);
            })
          ).subscribe();
        }),
        catchError(error => {
          this.logr.log("endSigninMainWindow -> signinRedirectCallback Error!\n", error);
          throw new Error("signinRedirectCallback() has encountered and error!");
        })
      ).subscribe();
  }

  getClaims(userId:string) {
    if(!this.claimsIsLoaded) {
      of(true).pipe(
        delay(1050),// this delay is to accomodate the throtleTime in signin redirect process
        switchMap(() => this.acc.get(userId)),
        map((claims) => {
          const roles = claims.filter((z) => z.claimType == claimTypes.USER_TYPE).map((a) => a.claimValue);
          const userId: string = claims.find( (z) => z.claimType == claimTypes.USERID ).claimValue;

          this.store.dispatch( STSActions.loadProfileSuccess({ claims }) );
          return {
            ...claims.reduce((a, v) => ({ ...a, [v.claimType]: v.claimValue }), {}),
            user_type: roles,
            sub: userId
          };
        }), shareReplay(1)).subscribe((claims: any) => {
          this.claimsIsLoaded = true;

          const { idp, langCode } = this.jwt.decodeToken( this._user.access_token );
          const additionalUserClaims = {
            idp,
            role: claims.user_type,
            token: this._user.access_token,
            name: `${claims.given_name} ${claims.family_name}`,
            synlabId: claims.synlab_id,
            country: claims.country,
            email: claims.email,
          };

          this.store.dispatch( STSActions.loadStssSuccess({ data: additionalUserClaims }) );
          this.profileInfo = { ...claims, ...additionalUserClaims };

          this.userInfo.next({ ...claims, idp, langCode });
          this.store.dispatch(STSActions.addUserInfo({ data: {...claims, idp, langCode} }));
        });
    }
  }
}
