import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { SearchCountryField, PhoneNumberFormat } from "ngx-intl-tel-input";
import * as libphonenumber from "google-libphonenumber";
import { PhoneValidationService } from "src/app/services/phone-validation/phone-validation.service";

@Component({
  selector: "app-mobile-number-picker",
  templateUrl: "./mobile-number-picker.component.html",
  styleUrls: ["./mobile-number-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobileNumberPickerComponent),
      multi: true,
    },
  ],
})

export class MobileNumberPickerComponent implements ControlValueAccessor, OnDestroy, OnInit, AfterViewInit {

  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;

  disabled = false;
  private onTouched!: Function;
  private onChanged!: Function;

  @Input() selectedCountryISO = "";
  @Input() placeholder = "";
  @Input() value = "";
  @Input() name = "phone";
  @Input() countryControl: UntypedFormControl;
  @Input() numberControl: UntypedFormControl;
  @Output() fgEvent: EventEmitter<UntypedFormGroup> = new EventEmitter();
  subscriptions = [];

  phoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl(""),
  });

  private phoneInput: AbstractControl;

  constructor(private pvs:PhoneValidationService) {}

  ngOnInit(): void {
    this.phoneForm.reset();
    this.phoneInput = this.phoneForm.controls["phone"];
    if(this.numberControl) {
      const phoneValue = this.numberControl.value;
      this.phoneInput.setValue(this.getPhone(phoneValue))
    }
    this.subscriptions.push(this.phoneForm.get('phone').valueChanges.subscribe(phoneObj => {
      if(this.countryControl && typeof phoneObj?.countryCode === 'string') {
        this.countryControl.setValue(phoneObj.countryCode)
      }
      if(this.numberControl && typeof phoneObj?.e164Number === 'string') {
        this.numberControl.setValue(phoneObj.e164Number.slice(1))
      }
      if (this.pvs.isValidNumber(phoneObj)) {
        this.phoneInput.setErrors(null);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit(): void {
    if(this.value) {
      this.phoneInput.setValue(this.value);
    }
  }

  writeValue(value: string): void {
    this.phoneInput.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.subscriptions.push(
      this.phoneInput.valueChanges.subscribe((n) => {
        if (this.phoneInput.errors == null && this.phoneInput.valid) {
          this.onChanged = fn(n);
        } else {
          this.onChanged = fn(null);
        }
      })
    );
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.phoneInput.disable();
    } else {
      this.phoneInput.enable();
    }
  }

  sendPhoneFormGroupValue(){
    this.fgEvent.emit(this.phoneForm);
  }

  private getPhone(phone: string) {
    try {
      // https://github.com/ruimarinho/google-libphonenumber
      const PNF = libphonenumber.PhoneNumberFormat;
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      if (phone && phoneUtil.isValidNumber(phoneUtil.parse(`+${phone}`))) {
        const number = phoneUtil.parseAndKeepRawInput(`+${phone}`, "");
        return {
          countryCode: phoneUtil.getRegionCodeForNumber(number),
          dialCode: `+${number.getCountryCode()}`,
          e164Number: phoneUtil.format(number, PNF.E164),
          internationalNumber: phoneUtil.format(number, PNF.INTERNATIONAL),
          nationalNumber: phoneUtil.format(number, PNF.NATIONAL),
          number: String(number.getNationalNumber()),
        };
      } else {
        // special override for Estonia to be able to accept sample numbers in https://github.com/SK-EID/MID/wiki/Test-number-for-automated-testing-in-DEMO
        if (`${phone}`.replace('+','').slice(0,3) == '372') {
          return {
            number: `${phone}`.slice(3),
            countryCode: 'EE'
          };
        }
        return { number: phone  };
      }
    } catch (error) {
      console.warn(
        "Error parsing phone. returning invalid raw number.",
        error
      );
      return {
        number: phone
      };
    }
  }

}
