import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ActivateAccountComponent } from './activate-account.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
  declarations: [ActivateAccountComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatProgressSpinnerModule,
    TranslocoModule
  ]
})
export class ActivateAccountModule { }
