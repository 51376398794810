import { HttpErrorResponse } from '@angular/common/http';

export const getErrorStatuscode = (error: HttpErrorResponse) => error?.status;
const isBadRequest = (error: HttpErrorResponse): boolean =>
  getErrorStatuscode(error) === 400

export function getErrorMessage(error: HttpErrorResponse) {
  if (isBadRequest(error)) { return error.error.message ?? error.error.ErrorMessage ?? error.message; }
  else if (typeof error === 'string') { return error; }
  else if (error instanceof Error) { return error.message }
  else { return "An unknown error occured"; }
}
