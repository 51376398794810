import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { merge, of } from "rxjs";
import { mergeMap, map, catchError, filter, tap, switchMap } from "rxjs/operators";
import { getErrorMessage } from "src/app/utils/error.util";
import * as TaraRegisterProfileActions from "src/app/@store/actions/tara-register-profile.actions";
import { TaraRegisterProfileApiService } from "src/app/services/tara-register-profile-api/tara-register-profile-api.service";
import {
  CheckIfTrueString,
  getClaimValueWithType,
} from "src/app/services/accounts/accounts.model";
import { claimTypes } from "src/app/appsettings";
import { TaraMigrateApiService } from "src/app/services/tara-migrate-api/tara-migrate-api.service";
import { TaraMigrateRequest } from "src/app/models/tara-migrate.model";
import { Router } from "@angular/router";

@Injectable()
export class TaraRegisterProfileEffects {
  constructor(
    private actions$: Actions,
    private taraRegisterProfileApi: TaraRegisterProfileApiService,
    private taraMigrateApi: TaraMigrateApiService,
    private _router: Router
  ) {}
  loadRegisterProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaraRegisterProfileActions.loadRegisterProfile),
      mergeMap((action) =>
        this.taraRegisterProfileApi.getRegisterProfile(action.regId)
      ),
      map((claims) =>
        TaraRegisterProfileActions.loadRegisterProfileSuccess({ data: claims })
      ),
      catchError((error: HttpErrorResponse, caught) => {
        const msg = getErrorMessage(error);
        return merge(
          of(
            TaraRegisterProfileActions.loadRegisterProfileFailure({
              error: msg,
              errorObject: error,
            })
          ),
          caught
        );
      })
    )
  );

  submitRegisterProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaraRegisterProfileActions.submitRegisterProfile),
      mergeMap((action) =>
        this.taraRegisterProfileApi
          .submitRegisterProfile( action.regId, action.data, action.hasEmail, action.userId )
          .pipe(map((res) => [res, action] as const))
      ),
      switchMap(
        ([response, action]) =>
        this.taraRegisterProfileApi.updateConsents(action.consents, response.userId)
          .pipe(map((res) => [response, action.data, res] as const))
      ),
      map(([response, claims, res]) =>
        TaraRegisterProfileActions.submitRegisterProfileSuccess({ response, claims, })
      ),
      catchError((error: HttpErrorResponse, caught) => {
        const msg = getErrorMessage(error);
        return merge(
          of(TaraRegisterProfileActions.submitRegisterProfileFailure({ error: msg, errorObject: error, })),
          caught
        );
      })
    )
  );

  /** no success or failure codes. as implemented in complete-profile */
  migrate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaraRegisterProfileActions.submitRegisterProfileSuccess),
        filter((action) => CheckIfTrueString(getClaimValueWithType(claimTypes.EE_MIGRATION_ACCEPTED, action.claims))),
        tap((action) => {
          const body: TaraMigrateRequest = {
            Ssn: getClaimValueWithType(claimTypes.SSN, action.claims),
            Email: getClaimValueWithType(claimTypes.EMAIL, action.claims),
            SynlabId: action.response.synlabId,
            CreatedDate: new Date().toISOString(),
          };

          this.taraMigrateApi.migrateOrders(body).subscribe(
            x => console.log("Sent migration request: Orders.", x),
            e => console.warn("Failed to send migration request: Orders.", e)
          );

          this.taraMigrateApi.migrateResults(body).subscribe(
            x => console.log("Sent migration request: results.", x),
            e => console.warn("Failed to send migration request: results.", e)
          );

        })
      ),
    { dispatch: false }
  );

  // check if <registrationDone> = true, if true, redirect to "A verification link has been sent page"
  loadRegisterProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaraRegisterProfileActions.loadRegisterProfileSuccess),
        map(({ data }) => {
          if (data?.registrationDone) {
            this._router.navigate(["registration", "update_tara"], {
              queryParams: { id: data?.userId },
            });
          }
        })
      ),
    { dispatch: false }
  );
}
