<ng-container *ngIf="(useNewNav$|async)">
  <syn-synlab-header (onLogout)="signout()" [disableLinks]="forceMFA$ | async"></syn-synlab-header>
</ng-container>
<router-outlet></router-outlet>

<div class="app-loading" *ngIf="isLangLoading$ | async">
  <div class="loading">
    <div class="text-center pt-5 pb-5 loading-indicator">
      <app-spinner class="app-spinner"></app-spinner>
    </div>
  </div>
</div>
