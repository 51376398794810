import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, NgModule, OnDestroy, Output } from '@angular/core';
import { HeaderModule } from '../header/header.component';
import { SynlabHeaderService } from './synlab-header.service';
import { takeUntil, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { of, ReplaySubject } from 'rxjs';

const HIDDEN_LINK_ROUTES = [
  '/registration/complete_registration_ptee',
  '/registration/secure_account',
  '/registration/complete-registration',
];

@Component({
  selector: 'syn-synlab-header',
  templateUrl: './synlab-header.component.html',
  styleUrls: ['./synlab-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SynlabHeaderService]
})
export class SynlabHeaderComponent implements OnDestroy {

  @HostBinding('class.header-visible')
  visible: boolean = true;
  @HostBinding('class.header-invisible')
  invisible: boolean = false;

  links$ = of([]);
  user$ = of(null);

  myAccountLink$ = this.headerService.myAccountLink$;
  brandLogoLink$ = this.headerService.brandLogoLink$;

  visible$ = this.headerService.visible$.pipe(tap((v) => {
    this.visible = v;
    this.invisible = !v;
  }));

  destory$ = new ReplaySubject(1);

  @Output() onLogout = new EventEmitter();
  @Input() disableLinks:boolean = false;

  constructor(
    private headerService: SynlabHeaderService,
    private route: Router) {

    this.route.events.pipe(
        takeUntil(this.destory$)
      ).subscribe(e => {
        if (e instanceof NavigationEnd && !e.url.includes('renewtoken') ) {
          console.log(e.url)
          if(HIDDEN_LINK_ROUTES.some(u => e.url.includes(u))) {
            this.links$ = of([]);
            this.user$ = of(null);
          }
          else {
            this.links$ = this.headerService.links$;
            this.user$ = this.headerService.user$;
          }
        }
     })
  }

  logout() {
    this.onLogout.emit();
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete();
  }

}
@NgModule({
  imports: [CommonModule, HeaderModule],
  declarations: [SynlabHeaderComponent],
  exports: [SynlabHeaderComponent]
})
export class SynlabHeaderModule {}
