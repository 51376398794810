<lib-header
  [class.shown]="(visible$|async)"
  [links]="(links$ | async) ?? []"
  [user]="(user$ | async) || undefined"
  [disableLinks]="disableLinks"
  (onLogout)="logout()"
  [myAccountLink]="myAccountLink$|async"
  [brandLogoLink]="brandLogoLink$|async">
</lib-header>

