<div class="component-wrapper" [formGroup]="phoneForm">
  <ngx-intl-tel-input
  [cssClass]="'tw-rounded-lg tw-py-4 tw-px-5 tw-border tw-w-full border-3 input'"
  [enableAutoCountrySelect]="true"
  [enablePlaceholder]="false"
  [searchCountryFlag]="true"
  [selectFirstCountry]="false"
  [selectedCountryISO]="selectedCountryISO"
  [maxLength]="15"
  [phoneValidation]="true"
  [separateDialCode]="true"
  [numberFormat]="PhoneNumberFormat.National"
  [attr.name]="name"
  [searchCountryPlaceholder]="placeholder"
  formControlName="phone"
  (input)="sendPhoneFormGroupValue()"
  (blur)="sendPhoneFormGroupValue()"
  ></ngx-intl-tel-input>
</div>
